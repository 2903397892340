import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["emailTemplate", "emailSubject", "emailBody"]

  changeEmailTemplate(event) {
    const selectedValue = event.target.value;
    fetch(`/backend/email_templates/${selectedValue}.json`)
        .then(response => response.json())
        .then(data => {
          this.emailSubjectTarget.value = data.subject;
          this.emailSubjectTarget.classList.add('animate__animated', 'animate__pulse');
          this.emailBodyTarget.editor.setSelectedRange([0, 0]);
          this.emailBodyTarget.editor.insertHTML(data.body.body);
          this.emailBodyTarget.classList.remove('animate__animated', 'animate__pulse');
          this.emailBodyTarget.classList.add('animate__animated', 'animate__pulse');
        });
  }
}
