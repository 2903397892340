import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/esm/plugins/remove_button/plugin'
import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'

//const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = [ "input" ]

    connect() {

        this.tomselect = new TomSelect(this.inputTarget, {
            plugins: {
                remove_button: {
                    title: 'Entfernen'
                }
            },
            // enable create unless the input target create is set to false
            create: this.inputTarget.dataset.tomselectCreate,
            placeholder: "Bitte auswählen",
            render: {
                no_results: function (data, escape) {
                    return '<div class="no-results">Keine Ergebnisse für "' + escape(data.input) + '"</div>';
                },
                option_create: function(data, escape) {
                    return '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen &hellip;</div>';
                },
            },

        })

    }



    disconnect() {
        this.tomselect.destroy(this.inputTarget)
    }
}